.group {
  margin-top: 30px;
  height: 130px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  display: flex;
  padding: 0 20px;
  background-color: #fff;
  align-items: center;
}

.reel {
  flex: 1;
  justify-content: center;
  overflow: hidden;
  position: relative;
  border-right: 2px solid rgba(0, 0, 0, 0.5);
  margin-top: 2px;
}

.reel-last {
  flex: 1;
  justify-content: center;
  overflow: hidden;
  position: relative;
  margin-top: 2px;
}

.reel-number {
  margin: 6px 0 0;
  font-size: 29px;
}

.div-arrow-up {
  width: 100%;
  top: 7px;
  height: 19px;
  position: absolute;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
}

.div-arrow-down {
  width: 100%;
  height: 18px;
  margin-top: -20px;
  position: absolute;
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  align-items: center;
}

.area-click-down {
  z-index: 1;
  width: 100%;
  top: 64px;
  height: 48px;
  position: absolute;
  cursor: pointer;
}

.area-click-up {
  z-index: 1;
  width: 100%;
  top: -1px;
  height: 48px;
  position: absolute;
  cursor: pointer;
}

.arrow-up {
  width: 30px;
  margin-top: -10px;
}

.arrow-down {
  width: 30px;
  margin-top: -3px;
}
