@import url(https://fonts.googleapis.com/css?family=Inconsolata);
.slot {
  font-family: 'Inconsolata', serif;
  font-weight: bold;
  font-size: 75px;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  fill: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 190px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.5);
  background-color: var(--spin-background);
  padding-top: 13px;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: var(--card);
  overflow: hidden;
  border-radius: 5px;
  outline: none;
  padding: 20px;
  width: 250px;  
}

@media screen and (max-width: 579px) {
  .close {
    margin-top: 15px;
  }
}

@media screen and (min-width: 580px) {
  .close {
    margin-top: 30px;
  }
}

.modal-ok {
  height: 480px;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%),
    0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.modal-error {
  height: 150px;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%),
    0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.modal-container {
  margin-top: 40px;
}

.fade-in-text {
  -webkit-animation: fadeIn ease-out 1s;
          animation: fadeIn ease-out 1s;
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  opacity: 0;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.group {
  margin-top: 30px;
  height: 130px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  display: flex;
  padding: 0 20px;
  background-color: #fff;
  align-items: center;
}

.reel {
  flex: 1 1;
  justify-content: center;
  overflow: hidden;
  position: relative;
  border-right: 2px solid rgba(0, 0, 0, 0.5);
  margin-top: 2px;
}

.reel-last {
  flex: 1 1;
  justify-content: center;
  overflow: hidden;
  position: relative;
  margin-top: 2px;
}

.reel-number {
  margin: 6px 0 0;
  font-size: 29px;
}

.div-arrow-up {
  width: 100%;
  top: 7px;
  height: 19px;
  position: absolute;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
}

.div-arrow-down {
  width: 100%;
  height: 18px;
  margin-top: -20px;
  position: absolute;
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  align-items: center;
}

.area-click-down {
  z-index: 1;
  width: 100%;
  top: 64px;
  height: 48px;
  position: absolute;
  cursor: pointer;
}

.area-click-up {
  z-index: 1;
  width: 100%;
  top: -1px;
  height: 48px;
  position: absolute;
  cursor: pointer;
}

.arrow-up {
  width: 30px;
  margin-top: -10px;
}

.arrow-down {
  width: 30px;
  margin-top: -3px;
}

.container {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: auto !important;
  margin: 0 auto 0;
}
.card {
  align-items: center;
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 1);
  transition: 0.3s;
  border-radius: 5px;
  background-color: var(--card);
}

.card.disable * {
  pointer-events: none;
}

@media (max-width: 600px) {
  .card {
    width: 100%;
    height: 374px;
  }
}

@media (min-width: 601px) {
  .card {
    min-width: 450px;
    height: 374px;
  }
}

.card-header {
  display: -webkit-box;
  border-radius: 5px 5px 0 0;
  height: 56px;
  background-color: var(--main-color);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
}

.card-header-text {
  color: var(--text);
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.5;
  font-size: 1.25rem;
  text-align: left;
  font-weight: bold;
  margin-left: 18px;
  margin-top: 15px;
}

.card-text {
  color: var(--text);
}

.container-card {
  padding: 2px 16px;
}

.container-button-header {
  margin-top: 0;
}

.button-header {
  cursor: pointer;
  width: 33px;
  height: 25px;
  background-color: #e0e0e0;
  border: none;
  border-radius: 5px;
  color: #000;
  margin-right: 8px;
}

.button-header:hover {
  background-color: #d0d0d0;
}

.button-header-active {
  cursor: pointer;
  width: 33px;
  height: 25px;
  background-color: var(--main-color);
  border: none;
  border-radius: 5px;
  color: var(--text);
  margin-right: 8px;
}

.container-input {
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  position: relative;
}

.label-input {
  position: absolute;
  font-size: 12px;
  margin-left: -18px;
  margin-top: -6px;
  background-color: var(--card);
  padding-left: 3px;
  padding-right: 3px;
  color: var(--text);
}

input {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input {
  outline: none;
  border-right: 0;
  border-top: 1px solid var(--border-color);
  border-left: 0;
  border-bottom: 1px solid var(--border-color);
  height: 26px;
  padding: 1%;
  width: 35%;
  text-align: center;
  background-color: var(--card);
  color: var(--text);
}
label,
input,
button.minus,
.plus {
  font-size: inherit;
  box-sizing: content-box;
}

button.minus,.plus {
  height: 26px;
  background-color: var(--card);
  color: var(--main-color);
  outline: none;
  cursor: pointer;
}

button.minus {
  border-radius: 5px 0 0 5px;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
  border-right: 0 solid var(--border-color);
  padding: 1% 2% 1% 3%;
}

button.plus {
  border-radius: 0 5px 5px 0;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  border-left: 0 solid var(--border-color);
  border-right: 1px solid var(--border-color);
  padding: 1% 3% 1% 2%;
}

.modal-prizes {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: var(--card);
  border-radius: 5px;
  outline: none;
  padding: 20px;
  width: 80%;
  height: 80%;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%),
    0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  font-family: 'Inconsolata', sans-serif;
  /* font-family: 'Helvetica Neue', Helvetica, Arial; */
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #3b3b3b;
  -webkit-font-smoothing: antialiased;
  /* font-smoothing: antialiased; */
}

.modal-container {
  overflow: auto;
  margin: 50px 0 0;
  max-height: 80%;
}


@media screen and (max-width: 580px) {
  body {
    font-size: 16px;
    line-height: 22px;
  }
}

.wrapper {
  margin: 0 auto;
  max-width: 1200px;
}
.table {
  margin: 0 0 40px 0;
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  display: table;
}
@media screen and (max-width: 580px) {
  .table {
    display: block;
  }
}
.row {
  display: table-row;
  background: #f6f6f6;
}
.row:nth-of-type(odd) {
  background: #e9e9e9;
}
.row.header {
  font-weight: 900;
  color: #fff;
  background: #ea6153;
}
.row.green {
  background: #27ae60;
}
.row.blue {
  background: #2980b9;
}
.row.cian {
  background: #29b9b2;
}
.row.pink {
  background: #a829b9;
}

@media screen and (max-width: 580px) {
  .row {
    padding: 14px 0 7px;
    display: block;
  }
  .row.header {
    padding: 0;
    height: 6px;
  }
  .row.header .cell {
    display: none;
  }
  .row .cell {
    margin-bottom: 10px;
  }
  .row .cell:before {
    margin-bottom: 3px;
    content: attr(data-title);
    min-width: 98px;
    font-size: 10px;
    line-height: 10px;
    font-weight: bold;
    text-transform: uppercase;
    color: #969696;
    display: block;
  }
}
.cell {
  padding: 6px 12px;
  display: table-cell;
}
@media screen and (max-width: 580px) {
  .cell {
    padding: 2px 16px;
    display: block;
  }
}


:root {
  touch-action: manipulation;
  --main-color: #e68107;
  --background: #14192c;
  --header: #e68107;
  --text: #fff;
  --spin-background: #fff;
  --card: #1f2a46;
  --border-color: #000;
  --loading: #fff;
}

body {
  touch-action: none;
  margin: 0;
  background-color: #14192c;
  background-color: var(--background);
  font-family: 'Inconsolata', sans-serif;
  font-size: 14px;
}

.container {
  padding: 8px;
  font-weight: bold;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

code {
  font-family: 'Inconsolata', sans-serif;
}

.button-default {
  cursor: pointer;
  background-color: #e68107;
  background-color: var(--main-color);
  border: none;
  color: #fff;
  color: var(--text);
  text-transform: uppercase;
  height: 31px;
  min-width: 64px;
  padding: 0 16px;
  border-radius: 5px;
  font-weight: bold;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%),
    0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.disable-select {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.modal-header {
  position: inherit;
  display: -webkit-box;
  border-radius: 5px 5px 0 0;
  height: 56px;
  background-color: #e68107;
  background-color: var(--main-color);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  width: 100%;
  margin-left: -20px;
  margin-top: -25px;
  margin-bottom: 10px;
}

.modal-footer {
  display: flex;
  flex: 1 1;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 15px;
}

