@import url('https://fonts.googleapis.com/css?family=Inconsolata');

:root {
  touch-action: manipulation;
  --main-color: #e68107;
  --background: #14192c;
  --header: #e68107;
  --text: #fff;
  --spin-background: #fff;
  --card: #1f2a46;
  --border-color: #000;
  --loading: #fff;
}

body {
  touch-action: none;
  margin: 0;
  background-color: var(--background);
  font-family: 'Inconsolata', sans-serif;
  font-size: 14px;
}

.container {
  padding: 8px;
  font-weight: bold;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

code {
  font-family: 'Inconsolata', sans-serif;
}

.button-default {
  cursor: pointer;
  background-color: var(--main-color);
  border: none;
  color: var(--text);
  text-transform: uppercase;
  height: 31px;
  min-width: 64px;
  padding: 0 16px;
  border-radius: 5px;
  font-weight: bold;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%),
    0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.disable-select {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.modal-header {
  position: inherit;
  display: -webkit-box;
  border-radius: 5px 5px 0 0;
  height: 56px;
  background-color: var(--main-color);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  width: 100%;
  margin-left: -20px;
  margin-top: -25px;
  margin-bottom: 10px;
}

.modal-footer {
  display: flex;
  flex: 1;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 15px;
}
