.slot {
  font-family: 'Inconsolata', serif;
  font-weight: bold;
  font-size: 75px;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  fill: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 190px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.5);
  background-color: var(--spin-background);
  padding-top: 13px;
}
