.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: var(--card);
  overflow: hidden;
  border-radius: 5px;
  outline: none;
  padding: 20px;
  width: 250px;  
}

@media screen and (max-width: 579px) {
  .close {
    margin-top: 15px;
  }
}

@media screen and (min-width: 580px) {
  .close {
    margin-top: 30px;
  }
}

.modal-ok {
  height: 480px;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%),
    0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.modal-error {
  height: 150px;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%),
    0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.modal-container {
  margin-top: 40px;
}

.fade-in-text {
  animation: fadeIn ease-out 1s;
  animation-delay: 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
