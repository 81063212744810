.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: auto !important;
  margin: 0 auto 0;
}
.card {
  align-items: center;
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 1);
  transition: 0.3s;
  border-radius: 5px;
  background-color: var(--card);
}

.card.disable * {
  pointer-events: none;
}

@media (max-width: 600px) {
  .card {
    width: 100%;
    height: 374px;
  }
}

@media (min-width: 601px) {
  .card {
    min-width: 450px;
    height: 374px;
  }
}

.card-header {
  display: -webkit-box;
  border-radius: 5px 5px 0 0;
  height: 56px;
  background-color: var(--main-color);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
}

.card-header-text {
  color: var(--text);
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.5;
  font-size: 1.25rem;
  text-align: left;
  font-weight: bold;
  margin-left: 18px;
  margin-top: 15px;
}

.card-text {
  color: var(--text);
}

.container-card {
  padding: 2px 16px;
}

.container-button-header {
  margin-top: 0;
}

.button-header {
  cursor: pointer;
  width: 33px;
  height: 25px;
  background-color: #e0e0e0;
  border: none;
  border-radius: 5px;
  color: #000;
  margin-right: 8px;
}

.button-header:hover {
  background-color: #d0d0d0;
}

.button-header-active {
  cursor: pointer;
  width: 33px;
  height: 25px;
  background-color: var(--main-color);
  border: none;
  border-radius: 5px;
  color: var(--text);
  margin-right: 8px;
}

.container-input {
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  position: relative;
}

.label-input {
  position: absolute;
  font-size: 12px;
  margin-left: -18px;
  margin-top: -6px;
  background-color: var(--card);
  padding-left: 3px;
  padding-right: 3px;
  color: var(--text);
}

input {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input {
  outline: none;
  border-right: 0;
  border-top: 1px solid var(--border-color);
  border-left: 0;
  border-bottom: 1px solid var(--border-color);
  height: 26px;
  padding: 1%;
  width: 35%;
  text-align: center;
  background-color: var(--card);
  color: var(--text);
}
label,
input,
button.minus,
.plus {
  font-size: inherit;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

button.minus,.plus {
  height: 26px;
  background-color: var(--card);
  color: var(--main-color);
  outline: none;
  cursor: pointer;
}

button.minus {
  border-radius: 5px 0 0 5px;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
  border-right: 0 solid var(--border-color);
  padding: 1% 2% 1% 3%;
}

button.plus {
  border-radius: 0 5px 5px 0;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  border-left: 0 solid var(--border-color);
  border-right: 1px solid var(--border-color);
  padding: 1% 3% 1% 2%;
}
